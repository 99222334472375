import React, { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";

const Tabs = ({
  tabs,
  width = '92%',
  height = '30px',
  activeTabColor = 'bg-gray-100',
  inactiveTabColor = 'bg-white',
  vectorColor = 'text-gray-600',
  handleOnTabChange,
  closeTab
}) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    getActiveTabs()
  }, [tabs])

  const getActiveTabs = () => {
    const localData = localStorage.getItem("activetabid")
    const id = localData ? parseInt(localData) : tabs[0]?.project_image_id

    const activeId = tabs.some(e => e.project_image_id === id) ? id : tabs[0]?.project_image_id
    setActiveTab(activeId)
    handleOnTabChange(activeId)
  }

  const handleActiveTabs = (id) => {
    localStorage.setItem("activetabid", id)
    setActiveTab(id)
    handleOnTabChange(id)
  }

  return (
    <div className={`flex bg-white gap-2 overflow-x-auto`} style={{ width, height }} key={tabs}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          onClick={() => handleActiveTabs(tab.project_image_id)}
          className={`cursor-pointer flex items-center justify-between text-nowrap p-1 
                      border border-solid border-gray-300 rounded-lg px-2  gap-4
                      ${activeTab === tab.project_image_id ? activeTabColor : inactiveTabColor}`}
          style={{ width: '211px', height: '30px' }}
        >
          <span className="flex items-center text-sm font-bold text-tech-t475467">
            {`${tab.project_name || ''} - ${tab.image_name || ''}`}
          </span>
          <span className={`${vectorColor}`}>
            <IoClose onClick={() => closeTab(tab.project_image_id)} />
          </span>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
