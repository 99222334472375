import { useEffect, useState } from "react";
import { InputWithIcon, NavBarDashboard, PrimaryButton, Button } from "src/components";
import { FaListUl } from "react-icons/fa6";
import { CiGrid41, CiSearch } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";
import { IoIosStarOutline } from "react-icons/io";
import { MdOutlineStar } from "react-icons/md";
import { addProjectActiveApi, getAllProjectList, getAllProjectListApi, getProjectListApi, updateFavouriteApi } from "src/apiUtils/apiPath";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FaDiagramProject } from "react-icons/fa6";
import { LiaProjectDiagramSolid } from "react-icons/lia";



const IMAGE_URL = process.env.REACT_APP_IMAGE_URL


export default function App() {
  const [projectList, setProjectList] = useState([]);
  const [view, setView] = useState('grid'); // 'grid' or 'list'
  const navigate = useNavigate()

  useEffect(() => { handleProjectList() }, []);

  const handleProjectList = async () => {
    try {
      const res = await getAllProjectListApi()
      if (res.status) {
        setProjectList(res.entity.map((e) => {
          return {
            ...e,
            project_name: e.name,
            image_name: e?.image_name,
            image: IMAGE_URL + e.result_image,
            updated_at: moment(e.updated_at).fromNow(),
            created_at: moment(e.created_at).format("DD-MM-YYYY")
          }
        }))
      } else {
        setProjectList([])
      }
      console.log('images', res)
    } catch (err) {
      console.log("Error------------------------>", err);
    }
  }

  return (
    <div>
      <NavBarDashboard refreshProject={() => handleProjectList()} />
      <div className="text-2xl font-semibold mb-4">Projects</div>
      <div className="md:flex md:justify-between items-center mb-4">
        <div className="flex gap-3">
          <InputWithIcon
            className="border-none bg-gray-50 px-4 py-2"
            placeholder="Search by Project Name..."
            endIcon={
              <Button className="p-2">
                <CiSearch />
              </Button>
            }
          />
        </div>
        <div className="flex gap-2 items-center">
          <Button
            onClick={() => setView('grid')}
            className={`border p-2 ${view === 'grid' ? 'bg-primary text-white' : ''}`}
          >
            <CiGrid41 />
          </Button>
          <Button
            className={`border p-2 ${view === 'list' ? 'bg-primary text-white' : ''}`}
            onClick={() => setView('list')}
          >
            <FaListUl />
          </Button>
          <Button className="border p-2">
            <BiSortAlt2 />
          </Button>
        </div>
      </div>

      <div className={view === 'grid' ? 'md:grid md:grid-cols-8 gap-4' : 'flex flex-col gap-4'}>
        {projectList.map((project) => (
          <div key={project.project_image_id} className="border p-4 rounded-lg shadow-md" onClick={() => navigate(`/dashboard/project/image-list?id=${project.id}`)}>
            <div className={`w-full ${view === 'grid' ? 'h-48' : 'h-32'} mb-3`}>
              {/* <FaFolder className="w-full h-full text-primary" /> */}
              {/* <FaDiagramProject className="w-full h-full text-primary" /> */}
              <LiaProjectDiagramSolid className="w-full h-full text-primary"/>
            </div>
            <div className="flex flex-col items-start space-y-2">
              <div className="flex justify-between w-full">
                <div className="text-lg font-black">{project.project_name}</div>
              </div>

              <div className="flex justify-between w-full">
                <div className="text-sm text-gray-500">{project.created_at}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
