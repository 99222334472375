import React from 'react';
import { NavBar, PrimaryButton } from 'src/components';
import { useNavigate } from "react-router-dom";


export default function App() {

    const navigate = useNavigate()

    return (
        <div className={`min-h-screen bg-cover  bg-no-repeat bg-homeBackground w-full h-full bg-blend-color-burn`}
            style={{
                backgroundImage: "url(/assets/homepage/homescreen/background.jpeg)"
            }}
        >
            <div className='md:mx-6 pt-3'>
                <NavBar signin />
                <main className="text-3xl font-bold underline mt-[70px]">
                    <div className="flex items-center">
                        <div className="grid grid-cols-1 md:grid-cols-2 items-center p-5">
                            <div className="text-white flex flex-col items-start justify-center m-2">
                                <div className="block sm:text-center mb-5 mt-2"><span>WELCOME TO INOVATRIK</span></div>
                                <div className="block text-4xl md:text-7xl font-semibold mt-2 mb-5">
                                    Make electrical and other types of technical drawings with <span className="text-primary">AI</span>
                                </div>
                                <div className="block text-sm md:text-[18px] font-light mt-5">
                                    Wave absence grace jealousy evil probable flour house soup welcome button fan fish scatter patriotic sudden sale deed man table note food
                                </div>
                                <div className="block text-xl mt-12 w-full">
                                    <PrimaryButton className=' text-white text-xl font-bold w-[352px] h-[50px] rounded-xl cursor-pointer' onClick={() => navigate("/login")}>
                                        Get Started
                                    </PrimaryButton>
                                </div>
                            </div>
                            <div className="flex items-center justify-center w-full md:h-full">
                                <div className="md:w-[659px] md:h-[673px]">
                                    <img src="/assets/homepage/homescreen/bannerimage.png" alt="bannerimage" />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}