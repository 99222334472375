import React from 'react';
import { IoMdClose } from "react-icons/io";

export default function Modal({ isOpen, title, content, onAccept, onDecline, children }) {
    if (!isOpen) return null;

    const handleClose = (e) => {
        if (e.target === e.currentTarget) {
            onDecline();
        }
    };

    return (
        <div
            id="default-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-brightness-90"
            onClick={handleClose}
        >
            <div className="relative p-4 max-w-[400px] w-full max-h-full bg-white rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
                {/* <div className="flex justify-end p-2">
                    <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                        onClick={onDecline}
                    >
                        <IoMdClose />
                    </button>
                </div> */}
                <div className="text-center p-4 md:p-5">
                    <h3 className="text-xl font-semibold text-gray-900">
                        {title}
                    </h3>
                </div>
                <div className="p-4 md:p-5 space-y-4">
                    {children}
                </div>
            </div>
        </div>
    );
}
