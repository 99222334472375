import React, { useRef, useState, useEffect } from 'react';
import { getAnnotationPredictionApi } from 'src/apiUtils/apiPath';

const IMAGE_URL = process.env.REACT_APP_AI_IMAGE_URL;

export default function ProjectEditor({ imageUrl, dataList, toolBarData, handleBaloonClick, emitTransform, handleAnnotation, id, onDecline }) {
  const canvasRef = useRef(null);
  const [transform, setTransform] = useState({
    scale: 100,
    rotate: 0,
    annotate: false,
    align: 'left'
  });

  const [annotationLineWidth, setAnnotationLineWidth] = useState(0)
  const [clickableAreas, setClickableAreas] = useState([]);

  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [isDrawing, setIsDrawing] = useState(false);  // Track if the mouse is currently drawing

  useEffect(() => {
    setTransform({
      scale: toolBarData?.scale || 100,
      annotate: toolBarData?.annotate || false,
      align: toolBarData?.align || 'left',
    });
  }, [toolBarData]);

  useEffect(() => {
    if (imageUrl) {
      highlightText();
    }
  }, [imageUrl, dataList, transform]);

  function highlightText() {
    const img = new Image();

    img.src = `${IMAGE_URL}${imageUrl}?canvas=true`;
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Clear the canvas before drawing the new image

      context.clearRect(0, 0, canvas.width, canvas.height);

      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);

      setAnnotationLineWidth(img.width * 0.0015)
      const newClickableAreas = [];

      dataList.forEach((word) => {

        let { balloon_id, x1, y1, x2 } = word;
        if (transform.align === 'right') {
          x1 = x2
        }
        y1 = y1 - y1 * 0.07

        const textWidth = context.measureText(balloon_id).width;
        const textHeight = img.width * 0.015;
        context.font = `bold ${textHeight - (textHeight * 0.30)}pt Rethink`;
        context.lineWidth = img.width * 0.0015;

        const squareSize = Math.max(textWidth, textHeight);
        const textX = x1 - (textWidth / 2); // Center the text horizontally
        const textY = y1 - (textHeight / 2) + textHeight // Center the text vertically

        let rectX1 = x1 - (squareSize / 2)
        let rectY1 = y1 - (squareSize / 2)
        let rectSize = squareSize * 1.3

        // Draw background rectangle
        context.strokeStyle = '#F5B501';
        context.fillStyle = "rgba(245, 181, 1, 0.22)";
        context.fillRect(rectX1, rectY1, rectSize, rectSize);
        context.strokeRect(rectX1, rectY1, rectSize, rectSize);

        console.log(rectX1, rectY1, rectSize, rectSize)

        // Draw text
        context.fillStyle = 'black';
        context.fillText(balloon_id, textX, textY);
        newClickableAreas.push({
          data: word,
          x: rectX1,
          y: rectY1,
          size: rectSize,
        });
      });

      // context.lineWidth = 10;
      // context.strokeStyle = "#F5B501";
      // context.strokeRect(startX, startY, newAnnotation.x2 - startX, newAnnotation.y2 - startY);

      setClickableAreas(newClickableAreas);
    };
  }

  const handleClick = (event) => {
    if (transform.annotate) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = (event.clientX - rect.left) * (canvas.width / rect.width);
    const y = (event.clientY - rect.top) * (canvas.height / rect.height);

    clickableAreas.forEach(area => {
      if (
        x >= area.x &&
        x <= area.x + area.size &&
        y >= area.y &&
        y <= area.y + area.size
      ) {
        console.log(`Clicked on: ${area.data}`);
        handleBaloonClick(area.data);
      }
    });
  };

  const getMousePosition = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    return {
      x: (e.clientX - rect.left) * (canvas.width / rect.width),
      y: (e.clientY - rect.top) * (canvas.height / rect.height)
    };
  }

  const handleMouseDown = (e) => {
    if (!transform.annotate) return;
    const mousePos = getMousePosition(e);
    setStartX(mousePos.x);
    setStartY(mousePos.y);
    setIsDrawing(true);  // Start drawing
  };

  const handleMouseMove = (e) => {
    if (!transform.annotate || !isDrawing) return;  // Only draw when the mouse is down

    const mousePos = getMousePosition(e);
    const x2 = mousePos.x;
    const y2 = mousePos.y;
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    // Redraw image and existing dataList
    highlightText();

    // Draw the new rectangle
    context.lineWidth = annotationLineWidth;
    context.strokeStyle = "#F5B501";
    context.strokeRect(startX, startY, x2 - startX, y2 - startY);
  };

  const handleMouseUp = (e) => {
    if (!transform.annotate || !isDrawing) return;
    setIsDrawing(false);  // Stop drawing

    const mousePos = getMousePosition(e);
    const x2 = mousePos.x;
    const y2 = mousePos.y;

    // Save the new annotation to dataList
    // handleDrawingClick({
    //   x1: startX,
    //   y1: startY,
    //   x2,
    //   y2,
    //   balloon_id: 33
    // })
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    // Ensure that coordinates and dimensions are within canvas bounds
    const tempWidth = Math.max(x2 - startX, 0);
    const tempHeight = Math.max(y2 - startY, 0);

    // Handle potential negative start coordinates by adjusting them
    const adjustedStartX = Math.max(startX, 0);
    const adjustedStartY = Math.max(startY, 0);

    // Create a temporary canvas with the correct dimensions
    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = tempWidth;
    tempCanvas.height = tempHeight;
    const tempContext = tempCanvas?.getContext("2d");

    try {
      const imageData = context.getImageData(adjustedStartX, adjustedStartY, tempWidth, tempHeight);

      tempContext.putImageData(imageData, 0, 0);
      const dataURL = tempCanvas.toDataURL();
      console.log(dataURL); // Use this data URL as needed
      tempCanvas.toBlob(async (blob) => {
        const annotationData = {
          x1: startX,
          y1: startY,
          x2,
          y2,
          file: blob,
          classname: 'Others'
        }
        const formData = new FormData();
        Object.keys(annotationData).forEach(key => formData.append(key, annotationData[key]));
        formData.append('id', id)
        const res = await getAnnotationPredictionApi(formData);
        emitTransform({ ...transform, annotate: false })
        if (res.status) {
          onDecline();
        } else {
          console.error("Something went wrong");
        }
      });
    } catch (error) {
      console.error("Error getting image data:", error);
    }
  };

  return (
    <div className={`overflow-auto border border-gray-500 ${transform.annotate ? ' cursor-crosshair ' : ''}`} key={toolBarData}>
      <canvas
        ref={canvasRef}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{
          transform: `scale(${transform.scale / 100})`,
          transformOrigin: 'top left',
          width: '100%',
          height: 'auto',
          overflow: 'auto'
        }}
        key={imageUrl || toolBarData} // Force re-render when imageUrl changes
      />
    </div>
  );
}
