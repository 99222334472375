import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = () => {
  return <ToastContainer />;
};

export const notify = (msg) => toast.error(
  msg,
  {
    pauseOnHover: false,
    autoClose: 500,
    position: 'top-right',
    hideProgressBar: true
  }
);

export const notifySuccess = (msg) => toast.success(
  msg,
  {
    pauseOnHover: false,
    autoClose: 500,
    position: 'top-right',
    hideProgressBar: true
  }
);

export default Notification;
