import React from 'react';
import PropTypes from 'prop-types';
import { Show } from 'src/helpers/Show';

const DataTable = ({
  headLabel,
  dataList,
  isLoading = false,
}) => {
  return (
    <div>
      <div className="overflow-x-scroll">
        <table className="w-full">
          <thead className='bg-gray-50  border border-tech-t98A2B3'>
            <tr className=" border-CPAEAECF0 dark:border-navy-700 p-3">
              {headLabel.map((header, index) => (
                <th
                  key={`${header?.id} table ${index}`}
                  className={`p-3 pr-14 pb-[13px] text-start cursor-pointer`}
                >
                  {header?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <Show>
              <Show.When isTrue={isLoading}>
                <tr>
                  <td colSpan={headLabel.length} className="text-center py-4">
                    Loading...
                  </td>
                </tr>
              </Show.When>
              <Show.Else>
                <Show>
                  <Show.When isTrue={dataList.length > 0}>
                    {dataList.map((row, rowIndex) => (
                      <tr
                        key={rowIndex}
                        className="border-CPAEAECF0 dark:border-navy-700 "
                      >
                        {headLabel.map((header) => (
                          <td
                            key={`${rowIndex}_${header?.value}`}
                            className={`p-3 sm:text-[16px] w-${header?.width ? header?.width : '20'} ${rowIndex%2 === 0 ? ' bg-primary/10  border-b-2 border-primary/5' : ' bg-primary/25 border-b-2 border-primary/20 ' }`}
                          >
                            {row[header?.value] ?? "--"}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Show.When>
                  <Show.Else>
                    <tr>
                      <td colSpan={headLabel.length} className="text-center py-4 text-red-600 font-bold">
                        No Data Found
                      </td>
                    </tr>
                  </Show.Else>
                </Show>
              </Show.Else>
            </Show>
          </tbody>
        </table>
      </div>
    </div>
  );
};

DataTable.propTypes = {
  headLabel: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['left', 'center', 'right']),
    })
  ).isRequired,
  dataList: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default DataTable;
