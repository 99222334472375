/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { Show } from "src/helpers/Show";
import { TbLogout } from "react-icons/tb";
import MenuList from './MenuLIst'



const Sidebar = ({ emitOpen }) => {

    const username = localStorage.getItem("user_email")

    const [open, setOpen] = useState(true);

    const navigate = useNavigate()

    const [profileMenuOpen, setProfileMenuOpen] = useState(false);

    const logout = () => {
        localStorage.removeItem("UserLoginData")
        localStorage.removeItem("user_first_name")
        localStorage.removeItem("user_last_name")
        localStorage.removeItem("user_phone_no")
        localStorage.removeItem("user_email")
        navigate("/home")
    }

    useEffect(() => { emitOpen(open); }, [open]);


    return (
        <div
            className={`sm:none fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transform transition-all duration-300 ease-in-out dark:!bg-navy-800 dark:text-white xl:!z-0 translate-x-0 ${open ? "  w-[260px] " : "sm:-translate-x-90 md:w-[70px] sm:w-0"}`}
        >
            <div className="flex justify-end -mr-5">
                <div className="border-2 border-primary top-10 bg-white absolute p-2 rounded-full text-primary flex items-center justify-center cursor-pointer transition-transform duration-300 ease-in-out" onClick={() => setOpen(!open)}>
                    {open ? <MdArrowBackIos className="w-4 h-4" /> : <MdArrowForwardIos className="w-4 h-4" />}
                </div>
            </div>
            <Show>
                <Show.When isTrue={open}>
                    <div className="mx-[25px] mt-[35px] flex items-center cursor-pointer"  onClick={()=> navigate("/home")}>
                        <div className="mt-1 ml-0.5 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white pl-3 pr-3 transition-opacity duration-300 ease-in-out">
                            <div className='w-40 h-10'>
                                <img src="/assets/logo/logo.png" alt="logo" />
                            </div>
                        </div>
                    </div>
                </Show.When>
                <Show.Else>
                    <div className="mt-[25px] h-2.5 text-[26px] font-bold uppercase text-navy-700 dark:text-white pl-3 pr-3 transition-opacity duration-300 ease-in-out">
                        <div className='w-40 h-10 cursor-pointer' onClick={()=> navigate("/home")}>
                            <img
                                src="/assets/logo/logo.png"
                                alt="logo"
                                className="block xs:hidden sm:hidden"
                            />
                            <img
                                src="/assets/logo/favicon.png"
                                alt="logo"
                                className="hidden sm:block xs:block"
                            />
                        </div>
                    </div>
                </Show.Else>
            </Show>
            <div className="mt-[73px] mb-7" />
            <div className="mb-auto pt-1">
                <ul>
                    {/* {createLinks(routes)} */}
                    <MenuList open={open} />
                </ul>
            </div>
            <div className={`flex items-end ${open ? 'mx-[25px] justify-start' : 'justify-center'}`}>
                <div className="relative">
                    <div className="flex items-center gap-3 cursor-pointer" onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
                        <div className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-700 text-white">
                            {username.charAt(0).toUpperCase()}
                        </div>
                        {open && (
                            <div className="text-white font-normal ml-4">
                                {username.split('@')[0].toUpperCase()}
                            </div>
                        )}
                    </div>

                    {/* Profile Dropdown Menu */}
                    {profileMenuOpen && (
                        <div
                            className={`absolute ${open ? '-top-28 left-5' : '-top-4 left-[calc(100%+20px)]'} mt-2 w-48 bg-white rounded-lg shadow-lg dark:bg-navy-800`}
                        >
                            <div
                                className={`absolute ${open ? 'bottom-[-8px] right-4' : 'top-2 -left-2'} w-0 h-0 border-l-8 border-r-8 border-b-8 border-transparent ${open ? 'border-t-8 border-t-white dark:border-t-navy-800' : 'border-r-8 border-r-white dark:border-r-navy-800'}`}
                            ></div>
                            <ul className="py-2 flex items-center justify-center hover:bg-gray-100 dark:hover:bg-navy-700">
                                <li className="flex items-center px-4 py-2 text-sm cursor-pointer" onClick={logout}>
                                    <TbLogout className="h-6 w-6 font-medium text-lg mr-2" />
                                    <span className="hidden sm:block">Logout</span>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default Sidebar;
