import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from 'src/components';
import CreateProject from 'src/components/navbar_dashboard/CreateProject';


export default function App() {
    const [open, setOpen] = useState(true)

    return (
        <div className={`min-h-screen w-full h-full`}
        >
            <Sidebar emitOpen={(e) => setOpen(e)} />
            <div className="h-full w-full bg-lightPrimary">
                <main
                    className={`min-h-screen  mx-[12px]  h-full flex-none transition-all xl:pr-2  ${open ? 'md:ml-[260px]' : 'md:ml-[70px]'}`}
                >
                    <div className="px-6 pl-10 py-4">
                        <Outlet  context={{ open }}/>
                    </div>
                </main>
            </div>
        </div>
    )
}