import React, { useEffect, useState } from 'react';
import { FileUpload, InputWithIcon, Modal, PrimaryButton } from 'src/components';
import { createProjectApi, yoloAiApi } from 'src/apiUtils/apiPath';
import { BsCloudUpload } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import { notify } from '../toast';

export default function CreateProject({ refreshProject, onDecline }) {
    const [modal, setModal] = useState(true)

    const [projectData, setProjectData] = useState({
        file: '',
        project_name: '',
        image_name: ''
    })

    // useEffect(() => {
    //     setProjectData({
    //         file: '',
    //         project_name: '',
    //         image_name: ''
    //     })
    // }, [modal])

    const handleCreateNewProject = async () => {
        try {
            const formData = new FormData();
            Object.keys(projectData).forEach(key => formData.append(key, projectData[key]));
            const res = await createProjectApi(formData)
            if (res.status) {
                setProjectData({
                    file: '',
                    project_name: '',
                    image_name: ''
                })
                setModal(false)
                refreshProject()
                console.log('images', res)
            } else {
                notify("Something went wrong")
            }
        } catch (err) {
            console.log("Error------------------------>", err);
        }
    }

    const handleDecline = () => {
        onDecline()
        setModal(false)
    }

    return (
        <Modal
            isOpen={modal}
            title="Create New Project"
            onDecline={() => handleDecline()}
        >
            <div className='overflow-hidden'>
                <div className='overflow-auto'>
                    <div className="mb-4">
                        <label className="block font-medium mb-2">
                            Project Name
                            <InputWithIcon
                                className="w-full"
                                placeholder=""
                                onChange={(e) => setProjectData((prev) => ({ ...prev, project_name: e.target.value }))}
                            />
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="block font-medium mb-2">
                            Image Name
                            <InputWithIcon
                                className="w-full"
                                placeholder=""
                                onChange={(e) => setProjectData((prev) => ({ ...prev, image_name: e.target.value }))}
                            />
                        </label>
                    </div>
                    <div className="mb-4">
                        <div className="block font-medium mb-2">
                            Team
                            <div className="flex gap-3 mt-2">
                                <div className="flex justify-center items-center h-10 w-10 rounded-full border border-primary text-primary">
                                    <FaPlus className='text-primary' />
                                </div>
                                <div className="flex -space-x-4 overflow-hidden">
                                    {['technical', 'technical', 'technical', 'technical'].map((text, index) => (
                                        <div
                                            key={index}
                                            className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-700 text-white shadow-md"
                                        >
                                            {text.charAt(0).toUpperCase()}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block font-medium">
                        Upload Image
                        <FileUpload
                            divclass="w-full mt-2"
                            className="w-full mt-2"
                            handleFilePreview={(e) => setProjectData(prev => ({ ...prev, file: e.file }))}
                        >
                            <div className="bg-tech-tE3F5FF rounded-xl flex p-3 pl-5 pr-5 w-full gap-3 items-center justify-between">
                                <BsCloudUpload className="w-8 h-8" />
                                <span className=' text-xs  text-nowrap'>Drag and Drop image</span>
                                <button className="overflow-hidden bg-white rounded-full p-1 pl-3 pr-3 text-nowrap">
                                    {projectData.file ? projectData.file.name : 'Upload File'}
                                </button>
                            </div>
                        </FileUpload>
                    </div>
                    <div className='flex justify-center'>
                        <PrimaryButton className=" mt-6 mb-5" onClick={handleCreateNewProject}>
                            Create New Project
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}