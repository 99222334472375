import { Navigate, useRoutes } from 'react-router-dom';
import * as C from './commonimport'
import { AuthuProtectedRoute, RedirectToDashboard } from './MiddleWare';

const dashboardPageChildren = [
  { element: <Navigate to="/dashboard/home" />, index: true },
  { path: 'home', element: <C.Home /> },
  { path: 'favourite', element: <C.Favourite /> },
  { path: 'project', element: <C.ProjectList /> },
  { path: 'project/tabs', element: <C.Project/> },
  { path: 'project/image-list', element: <C.ImageList /> },
]

const routeList = [
  { path: '/', element: <Navigate to="home" /> },
  { path: '/home', element: <C.HomePage /> },
  { path: '/dashboard', element: <AuthuProtectedRoute><C.DashboardLayout /></AuthuProtectedRoute>, children: dashboardPageChildren },
  { path: '/login', element: <RedirectToDashboard><C.LoginPage /></RedirectToDashboard>, },
  { path: '/signup', element: <RedirectToDashboard><C.SignUpPage /></RedirectToDashboard>, },
  { path: '*', element: <Navigate to="/" replace />, },
]

export default function Router() {
  const routes = useRoutes(routeList);
  return routes;
}
