import React from 'react';
import { PrimaryButton } from 'src/components';
import { useNavigate } from "react-router-dom";
import { Show } from 'src/helpers/Show';

export default function NavBar({ signin = false, contactus = false, className = '' }) {
    const navigate = useNavigate()

    return (
        <nav className={` flex justify-between p-4 pl-6 pr-6 items-center ${className}`}>
            <div className='w-40 h-10 cursor-pointer' onClick={()=> navigate("/home")}>
                <img src="/assets/logo/logo.png" alt="logo" />
            </div>
            <Show.When isTrue={signin}>
                <div>
                    <PrimaryButton className='text-white text-xl font-bold rounded-xl'  onClick={()=> navigate("/login")}>
                        Sign In / Sign Up
                    </PrimaryButton>
                </div>
            </Show.When>
            <Show.When isTrue={contactus}>
                <div className='pr-6'>
                    <PrimaryButton className='text-white text-xl font-bold w-[150px] h-[50px] rounded-xl' onClick={()=>  navigate("/home")}>
                        Contact Us
                    </PrimaryButton>
                </div>
            </Show.When>

        </nav>
    )
}