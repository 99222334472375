import React, { useState } from 'react';
import { Button, FileUpload, InputWithIcon, Modal, PrimaryButton } from 'src/components';
import { GoPlus } from "react-icons/go";
import { HiOutlineUserAdd } from "react-icons/hi";
import { createProjectApi, yoloAiApi } from 'src/apiUtils/apiPath';
import { BsCloudUpload } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import { notify } from '../toast';
import CreateProject from './CreateProject';
import UploadImage from './UploadImage';
import { Show } from 'src/helpers/Show';

export default function NavBar({ className = '', refreshProject, showProject = true, id }) {
    const [modal, setModal] = useState(false)

    return (
        <>
            <nav className={` w-full flex justify-end mt-4 pl-6 items-center ${className}`}>
                <div className='flex gap-3'>

                    <Button className='flex items-center gap-2 justify-center bg-transparent text-nowrap w-full border border-tech-t475467 '>
                        <HiOutlineUserAdd /> Invite Member
                    </Button>

                    <PrimaryButton className={' flex items-center justify-center gap-2 text-white  w-full'} onClick={() => setModal(true)}>
                        <GoPlus />    {showProject ? 'Add Project' : 'Add Diagram'}
                    </PrimaryButton>
                </div>
            </nav>
            <Show.When isTrue={modal && showProject}>
                <CreateProject refreshProject={() => refreshProject()} onDecline={() => setModal(false)} />
            </Show.When>
            <Show.When isTrue={modal && !showProject}>
                <UploadImage refreshProject={() => refreshProject()} onDecline={() => setModal(false)} id={id}/>
            </Show.When>

        </>
    )
}