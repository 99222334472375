const numericPattern = /^[0-9]*$/;
export const toProperCase = str =>
    str?.replace(/_/g, ' ')?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase());

export const checkNumber = str => numericPattern.test(str)

export const checkPermission = (accessRoles = []) => {
    let roleData = localStorage.getItem("UserLoginData");
    roleData = roleData ? JSON.parse(roleData) : {};
    const userRoles = Array.isArray(roleData.roles) ? roleData.roles : [];
    console.log(userRoles)

    return userRoles.some((e) => accessRoles.includes(e.sub_role))

}

export const getAwsFileName = (url) => url ? url.substring(url.lastIndexOf('/') + 1)?.replace(/%20/g, ' ') : '';

export const checkEmpty = (value) => value !== '' && value !== null && value !== undefined && value !== 'undefined' && value !== 'null'