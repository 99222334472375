export const appApi = (url, method, body = '') =>
  new Promise(async (resolve, reject) => {
    const Token = localStorage.getItem("DeviceToken")
    let options = {
      method,
      headers: {
        "Consumer_key": "tech_123456",
        "Authorization": `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    };
    if (body) {
      options = { ...options, body };
    }
    try {
      const response = await fetch(url, options);
      const json = await response.json();
      if (json.status) {
        if (json.status === 'false') {
          reject(json.reason);
        } else {
          resolve(json);
        }
      } else {
        resolve(json);
      }
    } catch (error) {
      reject(error);
    }

  })

