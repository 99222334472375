export function validator(data) {
    const validateReturn = [];
    const validate = data?.validate ?? ['required'];

    const validations = {
        required: () => required(data?.value, data?.msg),
        fixedLength: () => fixedLength(data?.value, data?.length, data?.msg),
        maxLength: () => maxLength(data?.value, data?.maxLength, data?.msg),
        minLength: () => minLength(data?.value, data?.minLength, data?.msg),
        email: () => email(data?.value, data?.msg)
    };

    validate.forEach(validation => {
        const result = validations[validation]?.();
        if (result?.valid) validateReturn.push(result);
    });

    return validateReturn;
}

const required = (e, msg) => response(((Array.isArray(e) && e.length === 0) || e === '' || e === null || e === undefined), msg || 'This field is required');

const fixedLength = (e, length, msg) => response(e?.length !== length, msg || `This field must contains ${length} letter`);

const maxLength = (e, length, msg) => response(e?.length >= length, msg || `This field should be less than ${length}`);

const minLength = (e, length, msg) => response(e?.length <= length, msg || `This field should be greater than ${length}`);

const email = (e, msg) => {
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return response(!pattern.test(e), msg || `Email is Invalid`)
};

const response = (condition, msg) => {
    const returnRes = {}
    returnRes.msg = condition ? msg : ''
    returnRes.valid = condition
    return returnRes;
}