import { Navigate } from "react-router-dom"

export const AuthuProtectedRoute = ({ children }) => {
  const values = localStorage.getItem('UserLoginData')
  const deviceToken = localStorage.getItem("DeviceToken")
  console.log("Value of isAuthenticated in AuthuProtectedRoute----->", localStorage.getItem('UserLoginData'));

  if (values && values !== "" && deviceToken && deviceToken !== "") {
    return children
  } else {
    return <Navigate to="/login" />
  }
}


export const RedirectToDashboard = ({ children }) => {
  const values = localStorage.getItem('UserLoginData')
  const deviceToken = localStorage.getItem("DeviceToken")
  console.log("Value of isAuthenticated in RedirectToDashboard----->", localStorage.getItem('UserLoginData'));

  if (values && values !== "" && deviceToken && deviceToken !== "") {
    return <Navigate to={'/dashboard'} />
  } else {
    return children
  }
}