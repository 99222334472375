import { appApi } from './AppApi';
import { appFileUploadApi } from './AppMulitipartApi';
import { AppS3DownloadApi } from './AppS3DownloadApi';



const baseApiUrl = process.env.REACT_APP_API_URL
const baseAiUrl = process.env.REACT_APP_AI_URL

export const yoloAiApi = async (data) => await appFileUploadApi(`${baseAiUrl}detect/?threshold=0.2`, "post", data)

export const signUpApi = async (data) => await appApi(`${baseApiUrl}auth/user/signup`, "post", JSON.stringify(data))

export const validateOtpApi = async (data) => await appApi(`${baseApiUrl}auth/user/validate`, "post", JSON.stringify(data))

export const signUpUpdatePasswordApi = async (data) => await appApi(`${baseApiUrl}auth/user/password-update`, "post", JSON.stringify(data))

export const signInApi = async (data) => await appApi(`${baseApiUrl}auth/user/signin`, "post", JSON.stringify(data))

export const createProjectApi = async (data) => await appFileUploadApi(`${baseApiUrl}project/create`, "post", data)

export const getProjectListApi = async (data = false) => await appApi(`${baseApiUrl}project/list?isFavourite=${data}`, "get")

export const updateFavouriteApi = async (data) => await appApi(`${baseApiUrl}favourite/add`, "post", JSON.stringify(data))

export const addProjectActiveApi = async (data) => await appApi(`${baseApiUrl}project/active`, "post", JSON.stringify(data))

export const deleteProjectActiveApi = async (data) => await appApi(`${baseApiUrl}project/active`, "delete", JSON.stringify(data))

export const getActiveProjectListApi = async () => await appApi(`${baseApiUrl}project/active/list`, "get")

export const getActiveTabDataApi = async (id) => await appApi(`${baseApiUrl}project/active/tabdata?id=${id}`, "get")

export const getAllProjectImageListApi = async (id) => await appApi(`${baseApiUrl}project/image/list?id=${id}`, "get")

export const getAllProjectListApi = async () => await appApi(`${baseApiUrl}project/project/list`, "get")

export const updateBalloonedResultApi = async (data) => await appApi(`${baseApiUrl}project/annotated/result`, "post", JSON.stringify(data))

export const checkWhetherItsBalloonedApi = async (id) => await appApi(`${baseApiUrl}project/check/ballooned?id=${id}`, "get")

export const updateProjectImageApi = async (data) => await appFileUploadApi(`${baseApiUrl}project/image`, "post", data)

export const updateBalloonedDatatApi = async (data) => await appApi(`${baseApiUrl}project/ballooned-data`, "post", JSON.stringify(data))

export const deleteProjectBalloonDataApi = async (data) => await appApi(`${baseApiUrl}project/balloon`, "delete", JSON.stringify(data))

export const getAnnotationPredictionApi = async (data) => await appFileUploadApi(`${baseApiUrl}annotation/predict`, "post", data)

export const getClassesMasterListApi = async (data) => await appFileUploadApi(`${baseApiUrl}master/classes`, "get")