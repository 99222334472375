import React, { useState, useEffect } from 'react';
import { InputWithIcon, Button, PrimaryButton, SingleSelect } from 'src/components';
import { RiDeleteBin6Line } from "react-icons/ri";
import { Show } from 'src/helpers/Show';
import { deleteProjectBalloonDataApi, getClassesMasterListApi, updateBalloonedDatatApi } from 'src/apiUtils/apiPath';


export default function Modal({ isOpen, title, content, onAccept, onDecline, children, modalData }) {
    const [subtypeOption, setSubtypeOption] = useState([]);
    const [balloonData, setBalloonData] = useState({
        type: 'others',
        sub_type: '',
        unit: '',
        quantity: '',
        specification: '',
        tolerance_type: '',
        upper_tolerance: '',
        lower_tolerance: '',
        max_value: '',
        min_value: ''
    })

    const typeOption = [
        { value: 'dimension', label: 'Dimension' },
        { value: 'tolerance', label: 'Tolerance' },
        { value: 'surface_finish', label: 'Surface Finish' },
        { value: 'material', label: 'Material' },
        { value: 'geometric_tolerance', label: 'Geometric Tolerance' },
        { value: 'others', label: 'Others' }
    ]

    useEffect(() => {
        handleMasterList();
    }, []);

    const handleMasterList = async () => {
        const res = await getClassesMasterListApi();

        if (res.status) {
            const optionValue = res.entity.map(e => ({ label: e.classname, value: e.classname }));
            setSubtypeOption(optionValue);
        } else {
            setSubtypeOption([]);
        }
    };


    // const subtypeOption = [
    //     { value: 'Linear', label: 'Linear' },
    //     { value: 'Angular', label: 'Angular' },
    //     { value: 'Diameter', label: 'Diameter' },
    //     { value: 'Radius', label: 'Radius' },
    //     { value: 'Flatness', label: 'Flatness' },
    //     { value: 'Straightness', label: 'Straightness' },
    //     { value: 'Roundness', label: 'Roundness' },
    //     { value: 'Cylindricity', label: 'Cylindricity' },
    //     { value: 'Parallelism', label: 'Parallelism' },
    //     { value: 'Perpendicularity', label: 'Perpendicularity' },
    //     { value: 'Roughness', label: 'Roughness' },
    //     { value: 'Hardness', label: 'Hardness' },
    //     { value: 'Roughness', label: 'Roughness' },
    //     { value: 'Angle', label: 'Angle' },
    //     { value: 'sheet', label: 'sheet' },
    //     { value: 'Length', label: 'Length' },
    //     { value: 'others', label: 'Others' }
    // ]


    const unitOption = [
        { value: 'mm', label: 'Millimeters (mm)' },
        { value: 'cm', label: 'Centimeters (cm)' },
        { value: 'm', label: 'Meters (m)' },
        { value: 'in', label: 'Inches (in)' },
        { value: 'ft', label: 'Feet (ft)' },
        { value: 'others', label: 'Others' }
    ]

    const toleranceOption = [
        { value: 'others', label: 'Others' }
    ]


    const handleClose = (e) => {
        if (e.target === e.currentTarget) {
            onDecline();
        }
    };
    useEffect(() => {
        setBalloonData({
            id: modalData.id,
            type: modalData.type,
            sub_type: modalData.sub_type,
            unit: modalData.unit,
            quantity: modalData.quantity,
            specification: modalData.specification,
            tolerance_type: modalData.tolerance_type,
            upper_tolerance: modalData.upper_tolerance,
            lower_tolerance: modalData.lower_tolerance,
            max_value: modalData.max_value,
            min_value: modalData.min_value
        })
    }, [modalData])

    const updateBalloonedData = async (project_image_id) => {
        try {
            const res = await updateBalloonedDatatApi(balloonData)
            if (res.status) {
                onDecline();
            } else {
                console.log("something went wrong")
            }

            console.log('images', res)
        } catch (err) {
            console.log("Error------------------------>", err);
        }
    }

    const deleteBalloonedData = async () => {
        try {
            const res = await deleteProjectBalloonDataApi({id : modalData.id})
            if (res.status) {
                onDecline();
            } else {
                console.log("something went wrong")
            }

            console.log('images', res)
        } catch (err) {
            console.log("Error------------------------>", err);
        }
    }

    return (
        <Show>
            <Show.When isTrue={isOpen}>
                <div
                    id="default-modal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed inset-0 z-50 flex justify-end items-start w-full h-full bg-black bg-opacity-50 backdrop-brightness-100  overflow-hidden"
                    onClick={handleClose}
                >
                    <div className="relative p-4 max-w-[600px] w-full max-h-full mt-14 mr-2 bg-white rounded-lg shadow-lg overflow-auto" onClick={(e) => e.stopPropagation()}>
                        <div className="flex justify-between p-4 md:p-5">
                            <h3 className="text-xl font-semibold text-gray-900">
                                {title}
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent text-red-600 rounded-lg text-sm"
                                onClick={deleteBalloonedData}
                            >
                                <RiDeleteBin6Line className='w-6 h-6' />
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div className='grid grid-cols-2 gap-5'>
                                <div className="mb-4">
                                    <label className="block font-medium mb-2">
                                        Type
                                    </label>
                                    <SingleSelect
                                        className="w-full"
                                        placeholder=""
                                        value={balloonData.type}
                                        option={typeOption}
                                        onChange={(event) => setBalloonData(prev => ({ ...prev, type: event.target.value }))}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-medium mb-2">
                                        Sub-type
                                    </label>
                                    <SingleSelect
                                        className="w-full"
                                        placeholder=""
                                        option={subtypeOption}
                                        value={balloonData.sub_type}
                                        onChange={(event) => setBalloonData(prev => ({ ...prev, sub_type: event.target.value }))}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-medium mb-2">
                                        Units
                                    </label>
                                    <SingleSelect
                                        className="w-full"
                                        placeholder=""
                                        option={unitOption}
                                        value={balloonData.unit}
                                        onChange={(event) => setBalloonData(prev => ({ ...prev, unit: event.target.value }))}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-medium mb-2">
                                        Quantity
                                    </label>
                                    <InputWithIcon
                                        className="w-full"
                                        placeholder=""
                                        value={balloonData.quantity}
                                        onChange={(event) => setBalloonData(prev => ({ ...prev, quantity: event.target.value }))}
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block font-medium mb-2">
                                    Specification
                                </label>
                                <InputWithIcon
                                    className="w-full"
                                    placeholder=""
                                    value={balloonData.specification}
                                    onChange={(event) => setBalloonData(prev => ({ ...prev, 
                                        specification: event.target.value ,
                                        min_value: parseFloat(event.target.value) - (prev.lower_tolerance || 0),
                                        max_value: parseFloat(event.target.value) - (prev?.upper_tolerance||0)
                                    }))}
                                    endIcon={<div className='text-primary'>GDT</div>}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block font-medium mb-2">
                                    Tolerance Type
                                </label>
                                <SingleSelect
                                    className="w-1/2 mr-5"
                                    placeholder=""
                                    option={toleranceOption}
                                    value={balloonData.tolerance_type}
                                    onChange={(event) => setBalloonData(prev => ({ ...prev, tolerance_type: event.target.value }))}
                                />
                            </div>
                            <div className='grid grid-cols-2 gap-5'>
                                <div className="mb-4">
                                    <label className="block font-medium mb-2">
                                        Upper Tolerance
                                    </label>
                                    <InputWithIcon
                                        className="w-full"
                                        placeholder=""
                                        value={balloonData.upper_tolerance ?? 0}
                                        // disabled={modalData.className !== 'Length'}
                                        onChange={(event) => setBalloonData(prev => ({
                                            ...prev,
                                            upper_tolerance: event.target.value,
                                            max_value: parseFloat(prev?.specification) - event.target.value
                                        }))}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-medium mb-2">
                                        Lower Tolerance
                                    </label>
                                    <InputWithIcon
                                        className="w-full"
                                        placeholder=""
                                        value={balloonData.lower_tolerance ?? 0}
                                        // disabled={modalData.className !== 'Length'}
                                        onChange={(event) => setBalloonData(prev => ({
                                            ...prev,
                                            lower_tolerance: event.target.value,
                                            min_value: parseFloat(prev?.specification) - event.target.value
                                        }))}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-medium mb-2">
                                        Max Value
                                    </label>
                                    <InputWithIcon
                                        className="w-full"
                                        placeholder=""
                                        disabled
                                        value={balloonData.max_value ? parseFloat(balloonData.max_value).toFixed(4) : 0}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-medium mb-2">
                                        Min Value
                                    </label>
                                    <InputWithIcon
                                        className="w-full"
                                        placeholder=""
                                        disabled
                                        value={balloonData.min_value ? parseFloat(balloonData.min_value).toFixed(4) : 0}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-end gap-2'>
                                <Button className='border' onClick={() => onDecline()}>
                                    Cancel
                                </Button>
                                <PrimaryButton onClick={() => updateBalloonedData()}>
                                    Save
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Show.When>
            <Show.Else>
                {null}
            </Show.Else>
        </Show>
    );
}
