import React from "react";
const FileUpload = ({
    children,
    maxWidth = '100%',
    maxHeight = '200px',
    handleFilePreview,
    disabled = false,
    divclass = '',
    className
}) => {
    const fileInputRef = React.useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && !disabled) {
            // if (file.type.startsWith('image/') || file.type.startsWith('video/') || file.type.startsWith('audio/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                handleFilePreview({ preview: reader.result, file });
            };
            reader.readAsDataURL(file);
            // } else {
            //     handleFilePreview({ preview: '', file: '' });
            // }
        }
        event.target.value = '';
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];
        if (file) {
            // if (file.type.startsWith('image/') || file.type.startsWith('video/') || file.type.startsWith('audio/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                handleFilePreview({ preview: reader.result, file });
            };
            reader.readAsDataURL(file);
            // } else {
            //     handleFilePreview({ preview: '', file: '' });
            // }
        }
    };

    const handleButtonClick = () => {
        if (!disabled) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className={`${divclass}`}>
            <button
                className={className}
                onClick={() => handleButtonClick()}
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e)}
                style={{ maxWidth, maxHeight }}
                disabled={disabled}
            >
                {children}
            </button>
            <input
                ref={fileInputRef}
                type="file"
                onChange={(e) => handleFileChange(e)}
                hidden
            />
        </div>
    );
};

export default FileUpload;
