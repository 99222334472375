// FullPageLoader.js
import React from 'react';

const FullPageLoader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[var(--parenttheme-loaderbg)] z-[2000] h-full backdrop-brightness-50">
      <img
        // src="/assets/loader.svg"
        src="/assets/loader/loader.svg"
        alt="loader"
        className="loader-icon"
      />
    </div>
  );
};

export default FullPageLoader;
